import React from "react";
import { Link } from "react-router-dom";
import "../styles/componentStyles/Footer.css";
import logo from "../assets/logo.svg";
import slogan from "../assets/white-slogan.webp";
import facebook from "../assets/facebook-messenger-logo.svg";
import twitter from "../assets/twitter-logo.svg";
import instagram from "../assets/instagram-logo.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        {/* image of the footer to put in the background */}
        <div className="footer-background"></div>

        <div className="footer-content">
          {/* left side of the footer: main logo and slogan + social media icons */}
          <div className="footer-left">
            <div className="whole-logo">
              <Link to={"/"}>
                <img src={logo} alt="logo restaurante" />
              </Link>
              <Link to={"/"}>
                <img src={slogan} alt="essence perfected" />
              </Link>
            </div>
            <ul className="media-icons">
              <li>
                <a href="https://www.instagram.com/therock/" target="_blank" rel="noreferrer">
                  <img src={instagram} alt="Instagram"></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/?locale=es_ES"
                  target="_blank" rel="noreferrer"
                >
                  <img src={facebook} alt="Facebook"></img>
                </a>
              </li>
              <li>
                <a href="https://x.com/EllenDeGeneres" target="_blank" rel="noreferrer">
                  <img src={twitter} alt="Twitter/X"></img>
                </a>
              </li>
            </ul>
          </div>
          {/* Right side of the footer content: all the legal stuff */}
          <div className="footer-right">
            <ul className="footer-links">
              <li>
                <Link to="/PrivacyPolicy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/LegalStuff">Legal Stuff</Link>
              </li>
              <li>
                <Link to="/CookiePolicy">Cookie Policy</Link>
              </li>
              <li>
                <Link to="/acknowledgements">Acknowledgements</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* bottom footer bar in red */}
      <div className="footer-bottom-bar">
        <p>
          COPYRIGHT 2024 URBAN ESSENCE INC. &#169;{" "}
          <span>ALL RIGHTS RESERVED</span>
        </p>
      </div>
    </div>
  );
}
export default Footer;
