import React, { useState, useEffect } from "react";
import "../styles/componentStyles/Carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//desktop media
import desktopSliderImage1 from "../assets/images/slider-images/slider-image-1-desktop.jpg";
import desktopSliderImage2 from "../assets/images/slider-images/slider-image-2-desktop.jpg";
import desktopSliderImage3 from "../assets/images/slider-images/slider-image-3-desktop.jpg";

//mobile media
import mobileSliderImage1 from "../assets/images/slider-images/slider-image-1-mobile.jpg";
import mobileSliderImage2 from "../assets/images/slider-images/slider-image-2-mobile.jpg";
import mobileSliderImage3 from "../assets/images/slider-images/slider-image-3-mobile.jpg";

const settings = {
  dots: true,
  infinite: true,
  lazyLoad: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 9000,
  pauseOnHover: true,
};

function Carousel() {
  const [imageSet, setImageSet] = useState({
    image1: desktopSliderImage1,
    image2: desktopSliderImage2,
    image3: desktopSliderImage3,
  });

  // making sure that one set of images is used for desktop and the other set is for mobile
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setImageSet({
          image1: mobileSliderImage1,
          image2: mobileSliderImage2,
          image3: mobileSliderImage3,
        });
      } else {
        setImageSet({
          image1: desktopSliderImage1,
          image2: desktopSliderImage2,
          image3: desktopSliderImage3,
        });
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <img src={imageSet.image1} alt="Slide 1" />
        <img src={imageSet.image2} alt="Slide 2" />
        <img src={imageSet.image3} alt="Slide 3" />
      </Slider>
    </div>
  );
}

export default Carousel;
