import React, { useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "../assets/circled-logo.svg"; //

const CenterControl = ({ position }) => {
  const map = useMap();
  const handleClick = () => {
    map.setView(position, 26);
  };

  return (
    <button
      onClick={handleClick}
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        zIndex: 1000,
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      Center Map
    </button>
  );
};

const DisableScrollZoom = () => {
  const map = useMap();
  map.scrollWheelZoom.disable();
  return null;
};

const Map = () => {
  const position = [41.84194085005467, -89.48594641552003];

  const customIcon = new L.Icon({
    iconUrl: markerIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <div>
      <div
        style={{
          height: "60vh",
          width: "90vw",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <MapContainer
          center={position}
          zoom={18}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={customIcon}>
            <Popup>Find us here!</Popup>
          </Marker>
          <CenterControl position={position} />
          <DisableScrollZoom />
        </MapContainer>
      </div>
    </div>
  );
};

export default Map;
