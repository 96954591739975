import React from "react";
import "../styles/componentStyles/Subheader.css";

// basically making a reusable subheader with its own image for every page

const Subheader = ({ imageUrl, title }) => {
  return (
    <div className="subheader" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="subheader-content">
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default Subheader;
