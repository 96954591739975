import React, { useState } from "react";
import Subheader from "../components/Subheader";
import ReservationsSubheader from "../assets/images/reservations-header.jpg";
import "../styles/Reservations.css";
import reservImage from "../assets/images/reservations.jpg";
import Map from "../components/Map.js";
import emailjs from "emailjs-com";

const Reservations = () => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [people, setPeople] = useState(1);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // email js
    const serviceID = "service_0x85i5e";
    const templateID = "template_6qv5bqj";
    const userID = "qS093Q-OPDPqPKrLB";

    const templateParams = {
      from_name: nombre,
      to_email: email,
      date: date,
      people: people,
    };

    // sending emaik
    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log("Success!", response.status, response.text);
        setMessage(
          `Your reservation was successfully booked under the name ${nombre} on ${date}, with a table available for ${people} people. You should be getting a confirmation email at the provided address: ${email}.`
        );
        setShowPopup(true);
      })
      .catch((err) => {
        console.error("Failure.", err);
      });

    // cleaning up
    setNombre("");
    setEmail("");
    setDate("");
    setPeople(1);
  };

  // self explanatory
  const closePopup = () => {
    setShowPopup(false);
    setMessage("");
  };

  return (
    <div>
      <Subheader imageUrl={ReservationsSubheader} title="RESERVATIONS" />
      <div className="reservations-container">
        <img src={reservImage} alt="Reservations image" />
        <div className="form-container">
          <h3>Don't let anybody take your table! Book now:</h3>
          <form onSubmit={handleSubmit}>
            <fieldset>
              {/* name */}
              <div>
                <label htmlFor="nombre">Name: </label>
                <input
                  type="text"
                  id="nombre"
                  value={nombre}
                  placeholder="Name and last name"
                  onChange={(e) => setNombre(e.target.value)}
                  required
                  pattern="[A-Za-zÀ-ÿ ,.'\-]+"
                  title="Are you eight years old? Write your name properly"
                  autoComplete="name"
                />
              </div>

              {/* email */}
              <div>
                <label htmlFor="email">Email: </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  placeholder="youremail@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                  title="Please provide a valid email address"
                />
              </div>

              {/* date */}
              <div>
                <label htmlFor="date">Date: </label>
                <input
                  type="date"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>

              {/* number of people */}
              <div>
                <label htmlFor="people">Number of people: </label>
                <select
                  id="people"
                  value={people}
                  onChange={(e) => setPeople(Number(e.target.value))}
                  required
                >
                  <option value="" disabled>
                    Number of people
                  </option>
                  {[...Array(8)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>

              {/* submit */}
              <button type="submit">Book now</button>
            </fieldset>
          </form>

          {/* popup */}
          {showPopup && (
            <div className="popup-overlay">
              <div className="popup-content">
                <h4>Your booking has been confirmed</h4>
                <p>{message}</p>
                <button onClick={closePopup}>All done!</button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="map-section">
        <h5>Looking for us?</h5>
        <p>321 W 2nd St, Dixon, IL 61021, US</p>
        <Map />
      </div>
    </div>
  );
};

export default Reservations;
