import React, { useState } from "react";
import Subheader from "../components/Subheader";
import GroupMenuSubheader from "../assets/images/groupmenu-header.jpg";
import "../styles/GroupMenu.css";
import { Link } from "react-router-dom";

// images
import group1 from "../assets/images/group-menu/group-menu-1.jpg";
import group2 from "../assets/images/group-menu/group-menu-2.jpg";
import group3 from "../assets/images/group-menu/group-menu-3.jpg";

// menu data... i couldve put it in a different json but it wasn't that much so i think it'll be easier to find here, i just keep it collapsed for mental sanity

const menuData = [
  {
    menuName: "Classic Group Menu",
    description:
      "A selection of timeless dishes for a refined group dining experience. Ideal for casual celebrations with a touch of sophistication.",
    pricePerPerson: 40,
    image: group1,
    courses: {
      starters: [
        "Creamy Butternut Squash Soup with Truffle Oil",
        "Smoked Salmon Tartare with Avocado and Capers",
        "Seasonal Greens Salad with Citrus Vinaigrette",
      ],
      mains: [
        "Grilled Free-Range Chicken Breast with Rosemary Jus",
        "Seared Seabass with Lemon Butter and Asparagus",
        "Wild Mushroom Risotto with Parmesan and White Truffle Oil",
      ],
      desserts: [
        "Warm Chocolate Lava Cake with Vanilla Bean Ice Cream",
        "Citrus Tart with Fresh Berries",
        "Classic Tiramisu",
      ],
      drinks: [
        "House Red or White Wine",
        "Soft Drinks and Water",
        "Coffee or Tea",
      ],
    },
  },
  {
    menuName: "Premium Urban Feast",
    description:
      "A curated menu for urban foodies, offering elevated flavors and high-quality ingredients. Perfect for more formal gatherings or special occasions.",
    pricePerPerson: 60,
    image: group2,
    courses: {
      starters: [
        "Burrata with Heirloom Tomatoes and Basil Oil",
        "Grilled Octopus with Lemon and Paprika Aioli",
        "Foie Gras Terrine with Fig Compote and Brioche",
      ],
      mains: [
        "Filet Mignon with Red Wine Reduction and Roasted Vegetables",
        "Pan-Seared Halibut with Saffron Cream and Wild Rice",
        "Vegetarian Couscous with Roasted Seasonal Vegetables and Spices",
      ],
      desserts: [
        "Vanilla Panna Cotta with Raspberry Coulis",
        "Molten Salted Caramel Cake",
        "Selection of Artisanal Cheeses with Honey and Nuts",
      ],
      drinks: [
        "Premium Red or White Wine",
        "Craft Cocktails",
        "Soft Drinks and Water",
        "Espresso or Herbal Teas",
      ],
    },
  },
  {
    menuName: "Elegant Celebration Menu",
    description:
      "A gourmet menu designed for elegant celebrations, blending modern and traditional flavors. Ideal for upscale events or intimate gatherings.",
    pricePerPerson: 80,
    image: group3,
    courses: {
      starters: [
        "Oysters with Champagne Mignonette",
        "Lobster Bisque with Cognac Cream",
        "Beetroot Carpaccio with Goat Cheese and Pine Nuts",
      ],
      mains: [
        "Roast Rack of Lamb with Mint and Red Wine Jus",
        "Butter-Poached Lobster Tail with Saffron Risotto",
        "Porcini Mushroom Ravioli with Sage Brown Butter",
      ],
      desserts: [
        "Dark Chocolate and Gold Leaf Mousse",
        "Classic Crème Brûlée",
        "Lemon and Elderflower Sorbet",
      ],
      drinks: [
        "Champagne",
        "Sommelier-Selected Wines",
        "Signature Cocktails",
        "Gourmet Coffee and Fine Teas",
      ],
    },
  },
];

// let the coding begin!!

const GroupMenu = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCardClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <div>
      <Subheader imageUrl={GroupMenuSubheader} title="GROUP MENU" />
      <p className="introduction-group">
        Discover our specially curated group menus, each designed to enhance
        your dining experience. As you browse, you’ll see a brief overview of
        each menu, including a description and a sample image. To delve deeper,
        simply click on a menu card to expand it and reveal detailed
        information.
      </p>
      <div className="group-menu">
        <div className="menu-cards">
          {menuData.map((menu, index) => (
            <div
              key={index}
              className={`menu-card ${index === activeIndex ? "active" : ""}`}
              onClick={() => handleCardClick(index)}
            >
              <div className="overview-container">
                <img
                  src={menu.image}
                  alt={menu.menuName}
                  className="menu-image"
                />
                <div className="menu-info">
                  <h3>{menu.menuName}</h3>
                  <p>{menu.description}</p>

                  <div
                    className={`arrow ${index === activeIndex ? "open" : ""}`}
                  ></div>
                </div>
              </div>

              {index === activeIndex && (
                <div className="menu-details">
                  {Object.keys(menu.courses).map((course, i) => (
                    <div key={i}>
                      <h4>{course}</h4>
                      <ul>
                        {menu.courses[course].map((item, j) => (
                          <li key={j}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  <p className="price">
                    Price: {menu.pricePerPerson}€ per person
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="redirection-menu">
        <div className="redirection-content">
          <h5>READY TO BOOK YOUR NEXT ADVENTURE?</h5>
          <p>
            Plan your next special event or dining experience by making a
            reservation with us. Whether you're celebrating with a group or
            simply looking for an unforgettable meal, booking your table ensures
            you can enjoy all of our delicious offerings at your preferred time.
            We also cater to various dietary needs, so everyone in your party
            will find something to enjoy. Secure your spot today and look
            forward to a memorable dining experience.
          </p>
          <Link to="/Reservations" className="menu-button">
            TAKE ME TO RESERVATIONS!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GroupMenu;
