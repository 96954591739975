import React from "react";
import "../styles/Home.css";
import circledlogo from "../assets/circled-logo.svg";
import SpecialtiesCarousel from "../components/Carousel";
import Grid from "../components/Grid";
import Teleprompter from "../components/Teleprompter";
import Map from "../components/Map";

function Home() {
  // Handling the scrolling of the "FIND OUT" button
  const handleScroll = (e) => {
    e.preventDefault();
    const aboutSection = document.getElementById("about-us");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home-page">
      {/* landing part */}
      <div className="hero-image">
        <h2 className="sr-only">Food as an Experience</h2>
        <a
          href="#"
          className="btn-flip"
          data-back="LET'S GO"
          data-front="FIND OUT"
          onClick={handleScroll}
        ></a>
      </div>

      {/* about us section */}
      <div id="about-us" className="about-us-section">
        <img
          src={circledlogo}
          alt="urban essence logo"
          className="circled-logo"
        />
        <div className="about-us-text">
          <h2>A BIT OF HISTORY...</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            et mauris nisl. Morbi ac varius est. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Phasellus lectus risus, tempus auctor
            mattis ac, viverra a ipsum. Sed viverra, lacus sed gravida ultrices,
            tellus lectus congue lectus, nec malesuada orci ex quis orci.
            Maecenas mattis efficitur arcu non scelerisque. Duis malesuada urna
            vel mauris mattis, vitae placerat libero eleifend. Nullam ut
            facilisis sem. Integer in tincidunt mi, quis auctor urna. Curabitur
            laoreet at lectus quis lacinia. Nulla quis tellus nunc. Donec vel
            mattis dolor.
          </p>
        </div>
      </div>

      {/* grid thing with images */}
      <Grid />

      {/* Carrusel */}
      <div className="carousel-section">
        <h3>SOME OF OUR TASTIEST DISHES</h3>
        <SpecialtiesCarousel />
      </div>

      {/* this was too cool not to try */}
      <Teleprompter
        mainText=" THE MOST INCREDIBLE FOOD"
        secondaryText=" DELIVERED AT YOUR TABLE"
      />

      {/* reusing the map cause why not*/}
      <div className="seccion-mapita">
        <Map />
        <div className="texto-mapita">
          <h4>COME FIND US</h4>
          <p>321 W 2nd St, Dixon, IL 61021, US</p>
        </div>
      </div>
    </div>
  );
}
export default Home;
