// SmallText.js
import React from "react";
import "../styles/componentStyles/SmallText.css";

function SmallText() {
  return (
    <div className="small-text">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse et
        mauris nisl. Morbi ac varius est. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Phasellus lectus risus, tempus auctor
        mattis ac, viverra a ipsum. Sed viverra, lacus sed gravida ultrices.
      </p>
    </div>
  );
}

export default SmallText;
