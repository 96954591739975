import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/componentStyles/Header.css";
import logo from "../assets/logo.svg";
import slogan from "../assets/white-slogan.webp";
//mobile only imports
import mobileimage from "../assets/images/mobile-navbar-menu.jpg";
import twitter from "../assets/twitter-logo.svg";
import instagram from "../assets/instagram-logo.svg";

//Handling the toggle element for mobile users
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  //Handling the scrolling for general use
  const [scrollingPosit, setScrollPosit] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    //I am aware scrollY does not work with really old Internet Explorers. I hope they change their browsers soon.
    setScrollPosit(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //Content and stuff
  return (
    <div
      className={`navbar ${isOpen ? "open" : ""} ${
        scrollingPosit > 300 ? "hidden" : ""
      }`}
      style={{ opacity: 1 - scrollingPosit / 300 }}
    >
      <div className="navbarLeft">
        <Link to={"/"}>
          <img src={logo} alt="urban essence" className="navbar-logo" />
        </Link>
        {/* since the text is already in the image, i thought i'd add a hidden text with the h1 so that it shows up in the google search engine (?) hope thats right */}
        <h1 className="sr-only">Urban Essence</h1>
        <Link to={"/"}>
          <img
            src={slogan}
            alt="essence perfected"
            loading="lazy"
            className="navbar-slogan"
          />
        </Link>
      </div>
      <div className={`toggle ${isOpen ? "active" : ""}`} onClick={toggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      <nav className={`navbar-links ${isOpen ? "active" : ""}`}>
        <ul>
          <li>
            <img
              src={mobileimage}
              alt="mobile menu image"
              className="mobile-menu-image"
            />
          </li>
          <li>
            <Link to="/" onClick={toggle}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/Menu" onClick={toggle}>
              Menu
            </Link>
          </li>
          <li>
            <Link to="/Specialties" onClick={toggle}>
              Specialties
            </Link>
          </li>
          <li>
            <Link to="/GroupMenu" onClick={toggle}>
              Group menu
            </Link>
          </li>
          <li>
            <Link to="/Reservations" onClick={toggle}>
              Reservations
            </Link>
          </li>
        </ul>

        {/* social media icons for mobile */}
        <ul className="social-media">
          <li>
            <a
              href="https://x.com/EllenDeGeneres"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="Twitter/X"></img>
              <p>Twitter</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/therock/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="Instagram"></img>
              <p>Instagram</p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
