import React, { useState } from "react";
import Subheader from "../components/Subheader";
import menuHeader from "../assets/images/menu-header.jpg";
import menuData from "../assets/data/menuData.json";
import "../styles/Menu.css";
import Teleprompter from "../components/Teleprompter";

// importing menu gallery images - i'm sorry it's just 4. If i open photoshop one more time i am actually going to physically collapse.
import menu1 from "../assets/images/menu-gallery/menu-gal-1.jpg";
import menu2 from "../assets/images/menu-gallery/menu-gal-2.jpg";
import menu3 from "../assets/images/menu-gallery/menu-gal-3.jpg";
import menu4 from "../assets/images/menu-gallery/menu-gal-4.jpg";

// basically setting the menu up for mobile
function Menu() {
  const [activeCategory, setActiveCategory] = useState("Starters");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="menu">
      <Subheader imageUrl={menuHeader} title="MENU" />
      {/* navbar for each category */}
      <div className="navbar-menu">
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰ Categories
        </button>
        <ul className={`nav-menu ${isMenuOpen ? "active" : ""}`}>
          {menuData.map((category, index) => (
            <li key={index}>
              <button onClick={() => handleCategoryClick(category.category)}>
                {category.category}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Menu content */}
      <div className="menu-content">
        {menuData
          .filter((category) => category.category === activeCategory)
          .map((category, index) => (
            <div key={index}>
              <h3>{category.category}</h3>
              <ul>
                {category.items.map((item, idx) => (
                  <li key={idx}>
                    <strong>{item.name}</strong>: {item.description} -{" "}
                    {item.price}
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>

      {/* teleprompter is backkkkk yeaaa!!! */}
      <Teleprompter
        mainText=" OUR DISHES SLAP HARDER"
        secondaryText=" THAN YOUR LAST BAD DECISION"
      />

      {/* little overview of them dishes... */}
      <div className="small-gallery">
        <h3> Need some inspo? No problem, boo... We got you</h3>
        <div>
          <img src={menu1} alt="Menu image 1" />
          <img src={menu2} alt="Menu image 2" />
          <img src={menu3} alt="Menu image 3" />
          <img src={menu4} alt="Menu image 4" />
        </div>
      </div>
    </div>
  );
}

export default Menu;
