import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import PrivacyPolicy from "./pages/legal stuff pages/PrivacyPolicy";
import CookiePolicy from "./pages/legal stuff pages/CookiePolicy";
import LegalStuff from "./pages/legal stuff pages/LegalStuff";
import Specialties from "./pages/Specialties.js";
import GroupMenu from "./pages/GroupMenu.js";
import Reservations from "./pages/Reservations.js";
import reportWebVitals from "./reportWebVitals";
import "leaflet/dist/leaflet.css";
import Acknowledgements from "./pages/legal stuff pages/Acknowledgements.js";
import ScrollToTop from "./components/ScrollToTop.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="menu" element={<Menu />} />
          <Route path="Specialties" element={<Specialties />} />
          <Route path="GroupMenu" element={<GroupMenu />} />
          <Route path="Reservations" element={<Reservations />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="CookiePolicy" element={<CookiePolicy />} />
          <Route path="LegalStuff" element={<LegalStuff />} />
          <Route path="Acknowledgements" element={<Acknowledgements />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
