import React, { useEffect, useState } from "react";
import jsonData from "../../assets/data/acknowledgements.json";
import "../../styles/Acknowledgements.css";

const Acknowledgements = () => {
  return (
    <div className="acknowledgements">
      <h2>Acknowledgements</h2>
      <ul>
        <li>
          Esquites logo from Freepik, free license. Find it{" "}
          <a
            href="https://www.freepik.com/free-vector/esquites-logo-design-template_36142168.htm#fromView=search&page=3&position=4&uuid=45fbd57a-5c7f-4bc3-b7cd-daf36ae0e1f4"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            here
          </a>
          .
        </li>

        {jsonData.map((item, index) => (
          <li key={index}>
            Image on {item.location} page from Unsplash, authored by{" "}
            {item.author}. Find it{" "}
            <a href={item.imageUrl} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Acknowledgements;
