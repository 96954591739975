import React, { useEffect, useState } from "react";
import Subheader from "../components/Subheader";
import specialtiesSubheader from "../assets/images/specialties-header.jpg";
import "../styles/Specialties.css";
import Teleprompter from "../components/Teleprompter";

//importing card images because APPARENTLY its kinda hard to do it from the json, my brain is fried at this point, FUCK IT
import specialties1 from "../assets/images/specialties-images/specialties-1.jpg";
import specialties2 from "../assets/images/specialties-images/specialties-2.jpg";
import specialties3 from "../assets/images/specialties-images/specialties-3.jpg";
import specialties4 from "../assets/images/specialties-images/specialties-4.jpg";
import specialties5 from "../assets/images/specialties-images/specialties-5.jpg";
import specialties6 from "../assets/images/specialties-images/specialties-6.jpg";

//so now i gotta map them too! great!
const imageMapping = {
  image1: specialties1,
  image2: specialties2,
  image3: specialties3,
  image4: specialties4,
  image5: specialties5,
  image6: specialties6,
};

//all the json stuff i wrote...
const specialties = [
  {
    id: 1,
    name: "Poke bowl... sort of",
    description: "Sushi rice with local vegetables.",
    price: "15.99€",
    image: imageMapping["image1"],
  },
  {
    id: 2,
    name: "Traditional noodles",
    description: "Tender noodles cooked slowly to fit any taste, we hope",
    price: "22.50€",
    image: imageMapping["image2"],
  },
  {
    id: 3,
    name: "House Special Burger",
    description:
      "Local meat, fresh produce, what could go wrong? A lot. But we cook very well!",
    price: "8.99€",
    image: imageMapping["image3"],
  },
  {
    id: 4,
    name: "Broccoli (?) insalata",
    description: "I think that's broccoli, i'm pretty sure",
    price: "6.50€",
    image: imageMapping["image4"],
  },
  {
    id: 5,
    name: "More noodles",
    description:
      "We apparently do them incredibly! Enough to put it in 4 specialties!",
    price: "19.00€",
    image: imageMapping["image5"],
  },
  {
    id: 6,
    name: "Was I drunk on Unsplash?",
    description:
      "Apparently all noodle photography has a black background... my apologies",
    price: "14.50€",
    image: imageMapping["image6"],
  },
];

//the actual code! at last!!
const Specialties = () => {
  return (
    <div className="specialties-container">
      <Subheader imageUrl={specialtiesSubheader} title="SPECIALTIES" />

      <p>
        Welcome to our carefully curated selection of specialties, where every
        dish tells a story of flavor, tradition, and passion. From the freshest
        local ingredients to time-honored recipes passed down through
        generations, each plate is crafted with the utmost care to offer you an
        unforgettable dining experience. Whether you're craving something hearty
        and comforting or looking to try something new and adventurous, our menu
        has something for everyone. Let your taste buds embark on a journey of
        vibrant tastes, textures, and aromas that will leave you longing for
        more. Explore our specialties and discover why we're so proud of what we
        bring to the table."
      </p>

      <div className="specialties-grid">
        {specialties.map((dish) => (
          <div key={dish.id} className="specialty-card">
            <img src={dish.image} alt={dish.name} className="specialty-image" />
            <div className="specialty-details">
              <h4>{dish.name}</h4>
              <p>{dish.description}</p>
              <p className="specialty-price">{dish.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Specialties;
