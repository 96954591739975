import React from "react";
import "../styles/componentStyles/Grid.css";

// media importssssss
import grid1 from "../assets/images/grid-image-1.jpg";
import grid2 from "../assets/images/grid-image-2.jpg";
import grid3 from "../assets/images/grid-image-3.jpg";

const Grid = () => {
  return (
    <div className="grid-container">
      {/* fila 1 */}
      <div className="grid-item image">
        <img src={grid1} alt="grid image 1" />
      </div>
      <div className="grid-item text">
        <h3>OUR TEAM</h3>
        <p>
          Um eum vel magnatum voluptaquiam lant at elitatem qui offictatur? Nam
          et ium qui volo volut acepedit earisint aut maio. Modit latatur ad
          quatur, utendiati dollam lam et quis inum aut andit essim qui ipitis
        </p>
      </div>

      {/* fila 2 */}
      <div className="grid-item text">
        <h3>OUR PRODUCE</h3>
        <p>
          Um eum vel magnatum voluptaquiam lant at elitatem qui offictatur? Nam
          et ium qui volo volut acepedit earisint aut maio. Modit latatur ad
          quatur, utendiati dollam lam et quis inum aut andit essim qui ipitis
        </p>
      </div>
      <div className="grid-item image">
        <img src={grid2} alt="grid image 2" />
      </div>

      {/* fila 3 */}
      <div className="grid-item image">
        <img src={grid3} alt="grid image 3" />
      </div>
      <div className="grid-item text">
        <h3>DELIVERY</h3>
        <p>
          Um eum vel magnatum voluptaquiam lant at elitatem qui offictatur? Nam
          et ium qui volo volut acepedit earisint aut maio. Modit latatur ad
          quatur, utendiati dollam lam et quis inum aut andit essim qui ipitis
        </p>
      </div>
    </div>
  );
};

export default Grid;
