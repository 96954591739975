import React, { useState, useEffect } from "react";
import "../styles/componentStyles/CookieBanner.css";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    setFadeOut(true);
    setTimeout(() => {
      localStorage.setItem("cookiesAccepted", "true");
      setIsVisible(false);
    }, 300);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="CookieBanner">
      <div className={`cookie-overlay ${fadeOut ? "fade-out" : ""}`}></div>
      <div className={`cookie-banner ${fadeOut ? "fade-out" : ""}`}>
        <h5>WE USE COOKIES!</h5>
        <p>
          We use cookies to ensure you the best experience. By using our website
          you agree to our Cookie Policy.
        </p>
        <button onClick={acceptCookies}>ACCEPT</button>
      </div>
    </div>
  );
};

export default CookieBanner;
