import React from "react";
import "../styles/componentStyles/Teleprompter.css";

const Teleprompter = ({ mainText, secondaryText, speed = 10 }) => {
  return (
    <div className="teleprompter-container">
      <div
        className="teleprompter-text"
        style={{ animationDuration: `${speed}s` }}
      >
        {/* had to duplicate the text to make sure it would repeat accordingly lol */}
        <span className="main-text">{mainText}</span>
        <span className="secondary-text">{secondaryText}</span>
        <span className="main-text">{mainText}</span>
        <span className="secondary-text">{secondaryText}</span>
      </div>
    </div>
  );
};

export default Teleprompter;
